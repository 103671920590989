<template>
  <!-- 绩效-待评价记录-详细 by lbj -->
  <div class="jxWhenMyAssesList">
    <van-nav-bar
      :title="choAss.khplanname"
      left-text
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="overflow_div">
      <!-- 待评价用户列表 -->
      <van-row class="person">
        <van-col
          span="6"
          class="personlist"
          v-for="(item, index) in choAss.children"
          :key="index"
          v-show="item.checked"
        >
          <div class="viewZp">
            <div class="re">
              <img :src="item.picurl" />
            </div>
            <!-- 该功能有漏洞 先注释 -->
            <!-- <div @click="del(item)" v-show="hasAddPer>1 && false">
              <svg-icon class="del" icon-class="del"></svg-icon>
            </div> -->
          </div>
          <div class="personname">{{ item.empname }}</div>
          <div class="dept">
            {{ item.deptname }}
            <br />
            {{ item.staname }}
          </div>
        </van-col>
        <van-col
          span="6"
          class="personlist"
          v-show="canAddPer > 0 && false"
          @click="showBox"
        >
          <div class="viewZp">
            <svg-icon icon-class="add"></svg-icon>
          </div>
        </van-col>
      </van-row>
      <!-- 问题列表 -->
      <div class="problemBox">
        <div class="msg" v-html="choAss.remark"></div>
        <div class="class" v-for="(item, index) in data" :key="index">
          <div class="classTitle">
            <span class="red">*</span>
            <span class="classTitleName">{{ item.typename }}</span>
          </div>
          <div
            class="msg"
            v-html="item.typeremark"
            v-show="item.typeremark"
          ></div>
          <div class="problemList" v-if="checktype != 3">
            <div
              class="problemContainer"
              v-for="(ite, ind) in item.children"
              :style="ind + 1 == item.children.length ? 'border:0;' : ''"
              :key="ind"
            >
              <div class="problemTitle">
                {{ ind + 1 }}.{{ ite.indicatorname }}
              </div>
              <!-- ind是0或2 只是测试数据 -->
              <van-row class="procho" v-if="checktype == 1">
                <van-col span="8" class="dafenList" v-show="hasAddPer > 1">
                  <div
                    class="dafenName"
                    :style="i + 1 == ite.children.length ? 'border:0;' : ''"
                    v-for="(it, i) in ite.children"
                    :key="i"
                  >
                    {{ it.empname }}
                  </div>
                </van-col>
                <van-col span="8" class="dafenList" v-show="hasAddPer == 1">
                  <div class="dafenName" style="color:red">
                    当前评分
                  </div>
                </van-col>
                <van-col :span="hasAddPer > 1 ? 16 : 16" class="choslist">
                  <div
                    class="procho_dafen"
                    v-for="(it, i) in ite.children"
                    :key="i"
                  >
                    <span
                      style="font-size: 12px;"
                      :style="
                        it.selectscore < 0 ||
                        it.selectscore > it.score ||
                        !/^\d+$/.test(it.selectscore)
                          ? 'color:red;'
                          : ''
                      "
                      v-if="
                        it.selectscore < 0 ||
                          it.selectscore > it.score ||
                          !/^\d+$/.test(it.selectscore)
                      "
                      >（请在0 - {{ it.score }}之间评分）</span
                    >
                    <input
                      type="text"
                      :disabled="it.isedit != 1"
                      v-model="it.selectscore"
                    />

                    <strong
                      :style="
                        it.selectscore < 0 ||
                        it.selectscore > it.score ||
                        !/^\d+$/.test(it.selectscore)
                          ? 'color:red;'
                          : ''
                      "
                    >
                      {{ $t('jxWhenMyAssesListDetail.walTxt1') }}
                      <!-- <span style="font-size: 12px;"
                            v-if="
                          it.selectscore < 0 ||
                            it.selectscore > it.score ||
                            !/^\d+$/.test(it.selectscore)
                        ">（请在0 - {{ it.score }}之间评分）</span> -->
                    </strong>
                  </div>
                </van-col>
              </van-row>
              <van-row class="procho" v-else>
                <!-- 如果选了多名员工 hasAddPer就会大于1-->
                <van-col span="4" class="perlist" v-show="hasAddPer > 1">
                  <div class="tc"></div>
                  <div class="pername" v-for="(it, i) in ite.children" :key="i">
                    {{ it.empname }}
                  </div>
                </van-col>
                <van-col :span="hasAddPer > 1 ? 20 : 24" class="choslist">
                  <div class="tc chostitle">
                    <div
                      v-for="(it, i) in checknamelist"
                      :key="i"
                      :style="
                        'width:calc( 100% / ' + checknamelist.length + ')'
                      "
                    >
                      {{ it.selectname }}
                    </div>
                  </div>
                  <van-radio-group
                    v-model="it.selectindex"
                    direction="horizontal"
                    class="choser"
                    v-for="(it, i) in ite.children"
                    :key="i"
                    :disabled="it.isedit != 1"
                  >
                    <van-radio
                      v-for="(it, i) in checknamelist"
                      :key="i"
                      :name="it.selectindex"
                      :style="
                        'width:calc( 100% / ' + checknamelist.length + ')'
                      "
                    >
                      <template #icon="props">
                        <div class="checked" v-if="props.checked"></div>
                      </template>
                    </van-radio>
                  </van-radio-group>
                </van-col>
              </van-row>
              <!-- 针对单个人的直接评分方案显示各个评分 -->
              <div v-if="checktype == 1 && showOtherPj">
                <!-- 非自评显示自评分 -->
                <van-row
                  class="procho"
                  v-if="
                    ite.children[0].pjLevel != 0 && baseinfo.can_show_zp_score
                  "
                >
                  <van-col span="8" class="dafenList">
                    <div class="dafenName">
                      自评分
                    </div>
                  </van-col>
                  <van-col :span="16" class="choslist">
                    <div class="procho_dafen">
                      <input
                        type="text"
                        :disabled="true"
                        style="background:#e2e2e2"
                        v-model="ite.children[0].zp_score"
                      />
                      <strong>
                        {{ $t('jxWhenMyAssesListDetail.walTxt1') }}
                      </strong>
                    </div>
                  </van-col>
                </van-row>
                <div v-if="baseinfo.can_show_pj_score">
                  <van-row
                    class="procho"
                    v-for="pjl in ite.children[0].pjNum"
                    :key="pjl"
                  >
                    <van-col span="8" class="dafenList">
                      <div class="dafenName">
                        {{
                          ite.children[0].pjLevel == 1
                            ? baseinfo['sj_title' + pjl]
                            : ite.children[0].pjLevel == 2
                            ? baseinfo['tj_title' + pjl]
                            : baseinfo['xj_title' + pjl]
                        }}
                      </div>
                    </van-col>
                    <van-col :span="16" class="choslist">
                      <div class="procho_dafen">
                        <!-- :disabled="ite.children[0].canEditScor != pjl" -->
                        <input
                          v-if="ite.children[0].pjLevel == 1"
                          type="text"
                          :disabled="true"
                          style="background:#e2e2e2"
                          v-model="ite.children[0]['sj_score' + pjl]"
                        />
                        <input
                          v-else-if="ite.children[0].pjLevel == 2"
                          type="text"
                          :disabled="true"
                          style="background:#e2e2e2"
                          v-model="ite.children[0]['tj_score' + pjl]"
                        />
                        <input
                          v-else-if="ite.children[0].pjLevel == 3"
                          type="text"
                          :disabled="true"
                          style="background:#e2e2e2"
                          v-model="ite.children[0]['xj_score' + pjl]"
                        />

                        <strong>
                          {{ $t('jxWhenMyAssesListDetail.walTxt1') }}
                          <!-- <span style="font-size: 12px;"
                              v-if="
                          it.selectscore < 0 ||
                            it.selectscore > it.score ||
                            !/^\d+$/.test(it.selectscore)
                        ">（请在0 - {{ it.score }}之间评分）</span> -->
                        </strong>
                      </div>
                    </van-col>
                  </van-row>
                </div>
              </div>
            </div>
          </div>
          <div class="problemTable" v-else>
            <!-- 如果是1个人 -->
            <div
              class="problemTable_Danr"
              v-show="hasAddPer <= 1"
              style="display: none"
            >
              <div class="problemTable_Tit">
                <div class="problemTable_Tit_Bt">
                  {{ $t('jxWhenMyAssesListDetail.walTxt2') }}
                </div>
                <div class="problemTable_Tit_Bzhun">
                  <div class="problemTable_Tit_Khpd">
                    {{ $t('jxWhenMyAssesListDetail.walTxt3') }}
                  </div>
                  <div class="problemTable_Tit_Khpf">
                    {{ $t('jxWhenMyAssesListDetail.walTxt4') }}
                  </div>
                </div>
              </div>
              <div
                class="problemTable_Body"
                v-for="(ite, ind) in item.children"
                :key="ind"
                style="padding: 0 0.42667rem 0.26667rem 0.26667rem"
              >
                <h2 class="problemTable_Body_Tit">
                  {{ ind + 1 }}.{{ ite.indicatorname }}
                </h2>
                <ul class="problemTable_Body_Ul">
                  <li
                    class="problemTable_Body_Li"
                    v-for="(it, i) in ite.selectdata"
                    :key="i"
                  >
                    <div class="problemTable_Body_LEFT">
                      <div class="problemTable_Body_index">{{ i + 1 }}</div>
                      <div class="problemTable_Body_BiaoTi">
                        {{ it.selectname }}
                      </div>
                    </div>
                    <div class="problemTable_Body_RIGHT">
                      <div class="problemTable_Body_PD">
                        {{ it.selectscore
                        }}{{ $t('jxWhenMyAssesListDetail.walTxt1') }}
                      </div>
                      <div
                        class="problemTable_Body_PF"
                        v-for="(its, is) in ite.children"
                        :key="is"
                      >
                        <input
                          :disabled="its.isedit != 1"
                          type="radio"
                          v-model="its.selectscore"
                          :value="it.selectscore"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- 如果是多个人 -->
            <div class="problemTable_Duor" v-show="hasAddPer > 1">
              <div class="problemTable_Tit">
                <div class="problemTable_Tit_Bt">
                  {{ $t('jxWhenMyAssesListDetail.walTxt2') }}
                </div>
                <div class="problemTable_Tit_Bzhun">
                  <div class="problemTable_Tit_Khpd">
                    {{ $t('jxWhenMyAssesListDetail.walTxt3') }}
                  </div>
                  <div class="problemTable_Tit_Khpf">
                    {{ $t('jxWhenMyAssesListDetail.walTxt4') }}
                  </div>
                </div>
              </div>
              <div
                class="problemTable_Body"
                v-for="(ite, ind) in item.children"
                :key="ind"
              >
                <h2 class="problemTable_Body_Tit">
                  {{ ind + 1 }}.{{ ite.indicatorname }}
                </h2>
                <ul class="problemTable_Body_Ul">
                  <li
                    class="problemTable_Body_LiSt"
                    v-for="(it, i) in ite.selectdata"
                    :key="i"
                  >
                    <div class="problemTable_Body_Li">
                      <div class="problemTable_Body_LEFT">
                        <div class="problemTable_Body_index">{{ i + 1 }}</div>
                        <div class="problemTable_Body_BiaoTi">
                          {{ it.selectname }}
                        </div>
                      </div>
                      <div class="problemTable_Body_RIGHT">
                        <div class="problemTable_Body_PD">
                          {{ it.selectscore
                          }}{{ $t('jxWhenMyAssesListDetail.walTxt1') }}
                        </div>
                        <div
                          class="problemTable_Body_PF"
                          style="
                            font-size: 8px;
                            justify-content: center;
                            color: blue;
                          "
                        >
                          {{ $t('jxWhenMyAssesListDetail.walTxt5') }}
                        </div>
                      </div>
                    </div>
                    <van-row
                      class="procho"
                      :style="
                        item.children.length - 1 == ind
                          ? ''
                          : 'border-bottom: 1px solid #ccc;padding-bottom: 0.26667rem;'
                      "
                    >
                      <van-col span="18" class="dafenList">
                        <div
                          class="dafenName"
                          :style="
                            i + 1 == ite.children.length ? 'border:0;' : ''
                          "
                          v-for="(it, i) in ite.children"
                          :key="i"
                        >
                          {{ it.empname }}
                        </div>
                      </van-col>
                      <van-col span="6" class="choslist">
                        <div
                          class="procho_dafen_2"
                          v-for="(its, is) in ite.children"
                          :key="is"
                        >
                          <div class="procho_dafen_3">
                            <input
                              :disabled="its.isedit != 1"
                              type="radio"
                              v-model="its.selectscore"
                              :value="it.selectscore"
                            />
                          </div>
                          <!-- <strong>分</strong> -->
                        </div>
                      </van-col>
                    </van-row>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="btnbox">
          <div v-if="checktype != 2" style="color: red;font-size: 16px;">
            <p
              v-for="(value, key) in tjzdfArr"
              :key="key"
              style="margin:0;line-height: 32px;"
            >
              {{ value.empname }}得分：{{ value.totalfs }}
            </p>
          </div>
        </div>
        <div class="btnbox">
          <div
            v-if="checktype == 1 && showOtherPj"
            style="color: red;font-size: 16px;"
          >
            <p
              style="margin:0;line-height: 32px;"
              v-show="$t('jxWhenMyAssesListDetail.walTxt12')"
            >
              {{ $t('jxWhenMyAssesListDetail.walTxt12') }}{{ khscore }}
            </p>
          </div>
        </div>
        <div class="btnbox" style="justify-content: center;">
          <van-button
            v-show="isedit"
            type="info"
            size="small"
            class="plbtn"
            @click.stop="confirm()"
            >{{ $t('jxWhenMyAssesListDetail.walTxt6') }}</van-button
          >
        </div>
      </div>
    </div>
    <van-dialog
      v-model="show"
      :title="$t('jxWhenMyAssesListDetail.walTxt7')"
      :showCancelButton="true"
      @confirm="done"
    >
      <van-row class="person">
        <van-col
          span="8"
          class="personlist"
          v-for="(item, index) in choAss.children"
          :key="index"
          v-show="!item.checked"
          @click="selected(item)"
        >
          <div class="viewZp">
            <div class="re">
              <img :src="item.picurl" />
            </div>

            <div v-show="item.selected">
              <svg-icon class="del" icon-class="checkOutAnswer-1"></svg-icon>
            </div>
          </div>
          <div class="personname">{{ item.empname }}</div>
          <div class="dept">
            {{ item.deptname }}
            <br />
            {{ item.staname }}
          </div>
        </van-col>
      </van-row>
    </van-dialog>
    <!-- <div class="progress">
      <div class="progressSon" :style="{height:progress+'%'}"></div>
    </div> -->
  </div>
</template>

<script>
import { Toast } from 'vant'
import { Notify } from 'vant'
import { getEmp360PaperDetail, saveEmp360PaperDetail } from '@/api/wxjx.js'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      show: false, // 选人框是否显示
      choAss: {
        khplanname: '',
        khplanid: 0,
        empnamelist: '',
        remark: ''
      },
      canAddPer: 0, // 可选人数
      hasAddPer: 0, // 已选人数
      isedit: false, // 是否允许评价
      data: [],
      checknamelist: [],
      checktype: 2, // 1是直接评分（填空） 2是按类别评分（选择） 3按指标细项目评分（分类选择）
      tjzdf: 0,
      tjzdfArr: [],
      baseinfo: {},
      showOtherPj: false,
      khscore: 0 // 最终得分
    }
  },
  computed: {
    progress() {
      let arr = [],
        arr2 = [],
        p = 0
      let cansave = true
      this.data.forEach(item => {
        item.children.forEach(ite => {
          ite.children.forEach(it => {
            if (it.selectindex) {
              arr2.push(it)
            }
            arr.push(it)
          })
        })
      })
      p = parseInt((arr2.length / arr.length) * 100) // 百分率
      console.log(p)
      return p
    }
  },
  watch: {
    data: {
      handler(n) {
        for (let i = 0; i < this.tjzdfArr.length; i++) {
          this.tjzdfArr[i].totalfs = 0
        }
        let tj = 0
        for (let i = 0; i < n.length; i++) {
          const c1 = n[i].children
          for (let j = 0; j < c1.length; j++) {
            const c2 = c1[j].children
            for (let k = 0; k < c2.length; k++) {
              const c3 = c2[k].selectscore
              for (let l = 0; l < this.tjzdfArr.length; l++) {
                if (this.tjzdfArr[l].empid == c2[k].empid) {
                  if (!isNaN(c3) && c3) {
                    this.tjzdfArr[l].totalfs =
                      parseFloat(c3) + this.tjzdfArr[l].totalfs
                  }
                }
              }

              if (!isNaN(c3) && c3) {
                tj = parseFloat(c3) + tj
              }
            }
          }
        }
        console.log(this.tjzdfArr, 'c2[k]c2[k]')
        this.tjzdf = tj
        console.log(tj, 'jhdhsdhdh')
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // 接收传入的评价方案信息
    const v = sessionStorage.getItem('jxWhenMyAssesListDetail')
    if (v) {
      this.choAss = JSON.parse(v)
      this.showOtherPj = this.choAss.num == 1
      let nameArr = this.choAss ? this.choAss.children : []
      let newNameArr = []
      if (nameArr.length > 0) {
        newNameArr = nameArr.filter(item => {
          return item.checked
        })
      }
      this.tjzdfArr = newNameArr
      for (let i = 0; i < this.tjzdfArr.length; i++) {
        this.tjzdfArr[i].totalfs = 0
      }
      this.iniPer()
    } else {
      // 没有内容则回到上一页重新选择
      this.$router.push('/jxWhenMyAssesList')
    }
    this.getEmp360PaperDetail()
  },
  methods: {
    test() {
      console.log(this.progress)
    },
    // 回退
    onClickLeft() {
      this.$router.push('/jxWhenMyAssesList')
    },
    getEmp360PaperDetail() {
      let dd = []
      this.choAss.children.forEach(e => {
        if (e.checked) {
          const { mautoid, checkflag } = e
          dd.push({ mautoid, checkflag })
        }
      })
      let data = {
        username: this.userInfo.username,
        khplanid: this.choAss.khplanid,
        data: dd
      }
      getEmp360PaperDetail(data).then(res => {
        console.log(res, 'list')
        this.baseinfo = res.baseinfo[0]
        if (res.baseinfo[0].checktype == 1)
          this.khscore = res.baseinfo[0].khscore
        this.choAss.khplanname = res.baseinfo[0].khplanname
        this.choAss.khplanid = res.baseinfo[0].khplanid
        this.choAss.empnamelist = res.baseinfo[0].empnamelist
        this.choAss.remark = res.baseinfo[0].remark
          ? res.baseinfo[0].remark.replace(/\\n/g, '<br>')
          : ''
        this.checktype = res.baseinfo[0].checktype
        let typename = ''
        let indicatorname = ''
        let arr = []
        res.data.forEach(item => {
          if (item.checkflag == 0) {
            item.pjLevel = 0
            item.canEditScor = 0
          } else {
            const first = (item.checkflag + '').substr(0, 1)
            const second = (item.checkflag + '').substr(1, 1)
            item.pjLevel = first
            item.canEditScor = second
            if (first == 1) {
              item.pjNum = res.baseinfo[0].sjcount
            } else if (first == 2) {
              item.pjNum = res.baseinfo[0].tjcount
            } else if (first == 3) {
              item.pjNum = res.baseinfo[0].xjcount
            }
          }
          if (dd.length == 1) {
            /*
            20230926 lbj
            如果只选了一个人，就按这个标记(ispost)判断，如果选了多人评价，则保留之前的逻辑
            */
            this.isedit = !this.baseinfo.ispost
          } else {
            if (item.isedit === 1) this.isedit = true // 存在1笔可以编辑的评价
          }
          if (item.typename != typename) {
            typename = item.typename
            arr.push({
              typename: typename,
              typeremark: item.typeremark
                ? item.typeremark.replace(/\\n/g, '<br>')
                : '',
              children: []
            })
          }
          if (item.indicatorname != indicatorname) {
            indicatorname = item.indicatorname
              ? item.indicatorname.replace(/\\n/g, '\n')
              : item.indicatorname
            let selectdata = []
            if (this.checktype == 3) {
              let sstr = item.scoredesp
              let sarr = sstr.split('|')
              for (let k = 0; k < sarr.length; k++) {
                let sobj = sarr[k].split('@')
                let obj = {
                  selectindex: k + 1,
                  selectname: sobj[0],
                  selectscore: sobj[1]
                }
                selectdata.push(obj)
              }
            }
            arr[arr.length - 1].children.push({
              indicatorname: indicatorname,
              selectdata: selectdata,
              children: []
            })
          }
          arr[arr.length - 1].children[
            arr[arr.length - 1].children.length - 1
          ].children.push(item)
        })
        if (this.checktype == 3) {
        } else {
          this.checknamelist = res.checknamelist
        }
        this.data = arr
        console.log(this.data, 'arrarrarr')
      })
    },
    // 处理人员数据
    iniPer() {
      this.canAddPer = 0
      this.hasAddPer = 0
      this.choAss.children.forEach(e => {
        e.selected = e.selectindex ? true : false
        if (e.checked) {
          this.hasAddPer++
        } else {
          this.canAddPer++
        }
      })
    },
    // 删除人员
    del(e) {
      e.checked = false
      this.iniPer()
    },
    // 显示选人框
    showBox() {
      this.show = true
    },
    // 选中人员
    selected(e) {
      e.selected = !e.selected
      this.$forceUpdate()
    },
    // 选择完人员后
    done() {
      this.choAss.children.forEach(e => {
        if (e.selected) {
          e.checked = true
        }
      })
      this.iniPer()
    },
    //  提交
    confirm() {
      let arr = []
      let cansave = true
      this.data.forEach(item => {
        item.children.forEach(ite => {
          ite.children.forEach(it => {
            if (it.selectindex) {
              if (this.checktype == 2) {
                it.selectscore = this.getSelectscore(it.selectindex)
                it.scoredesp = ''
              } else if (this.checktype == 1) {
                it.scoredesp = ''
              } else if (this.checktype == 3) {
                console.log(ite, it)
              }
            } else if (it.selectscore != null && it.selectscore != '') {
              if (it.selectscore > it.score) {
                cansave = false
                Toast.fail(
                  it.indicator_short_name +
                    this.$t('jxWhenMyAssesListDetail.walTxt11')
                )
              }
              if (this.checktype == 1) {
                it.scoredesp = ''
              } else if (this.checktype == 3) {
                console.log(ite, it)
              }
            } else {
              console.log(ite, it, '1111111')

              cansave = false
            }
            arr.push(it)
          })
        })
      })
      console.log(arr)
      if (cansave) {
        let data = {
          username: this.userInfo.username,
          khplanid: this.choAss.khplanid,
          data: arr
        }
        Toast.loading({
          message: this.$t('jxWhenMyAssesListDetail.walTxt8'),
          forbidClick: true,
          duration: 0
        })
        saveEmp360PaperDetail(data).then(res => {
          this.khscore = res.data[0].khscore
          Toast.clear()
          Toast.success(this.$t('jxWhenMyAssesListDetail.walTxt9'))
          // this.onClickLeft()
        })
      } else {
        Notify({
          type: 'warning',
          message: this.$t('jxWhenMyAssesListDetail.walTxt10')
        })
      }
    },
    getSelectscore(selectindex) {
      let selectscore = 0
      this.checknamelist.forEach(e => {
        if (e.selectindex === selectindex) {
          selectscore = e.selectscore
        }
      })
      return selectscore
    }
  }
}
</script>

<style lang="less" scoped>
.jxWhenMyAssesList {
  height: 100vh;
  overflow: hidden;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .dafenList {
  }
  .dafenName {
    height: 1.04rem;
    line-height: 0.8rem;
    padding-bottom: 0.08rem;
    padding-top: 0.16rem;
    font-size: 0.37333rem;
    border-bottom: 1px solid #ddd;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .overflow_div {
    overflow: auto;
    height: calc(100% - 96px);
    .person {
      background: #fff;
      padding: 30px 20px 0;
      margin-bottom: 20px;
      .personlist {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 30px;
        .viewZp {
          width: 1.3rem;
          height: 1.3rem;
          position: relative;
          .re {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
          }
          img,
          .svg-icon {
            width: 100%;
            height: 100%;
          }
          .del {
            width: 36px;
            height: 36px;
            position: absolute;
            top: 0;
            right: -12px;
          }
        }
        .personname {
          font-size: 0.45rem;
          text-align: center;
        }
        .dept {
          font-size: 20px;
          color: #f00;
          text-align: center;
        }
      }
    }
    .problemBox {
      background: #fff;
      .msg {
        font-size: 32px;
        color: #333;
        border-bottom: 1px solid #ccc;
        padding: 30px 20px;
        line-height: 1.5;
        text-align: justify;
      }
      .btnbox {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        // justify-content: space-between;
        padding: 20px 30px;
        .plbtn {
          // width: 290px;
          width: 80%;
          height: 70px;
          margin: 0 !important;
          font-size: 36px;
        }
      }
      .class {
        padding: 0.26667rem 0.1rem;
        .classTitle {
          font-size: 32px;
          color: #000;
          font-weight: bold;
          .red {
            color: #f53838;
          }
        }
        .msg {
          font-size: 25px;
          color: #333;
        }
        .problemTable {
          margin: 10px 20px 0;
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          border-radius: 8px;
          .problemTable_Tit {
            width: 100%;
            background: #2b8df0;
            border-radius: 8px 8px 0 0;
            height: 72px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 32px 0 50px;
          }
          .problemTable_Tit_Bt {
            color: #fff;
            font-size: 32px;
          }
          .problemTable_Tit_Bzhun {
            display: flex;
            .problemTable_Tit_Khpd {
              width: 64px;
              font-size: 24px;
              color: #fff;
              text-align: center;
            }
            .problemTable_Tit_Khpf {
              width: 64px;
              font-size: 24px;
              color: #fff;
              text-align: center;
              margin-left: 20px;
            }
          }
          .problemTable_Body {
            width: 100%;
            padding: 0 0.26667rem 0.26667rem;
          }
          .problemTable_Body_Tit {
            margin: 0;
            font-size: 32px;
            color: #2b8df0;
            line-height: 1.5;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
        .problemList {
          border: 1px solid #ccc;
          border-top: 15px solid #2b8df0;
          border-radius: 10px;
          margin: 0.15rem 0.16667rem 0;
          padding: 0 20px 20px;
          font-size: 28px;
          .problemContainer {
            padding-bottom: 0.25rem;
            border-bottom: 1px solid #cccccc;
          }
          .problemTitle {
            color: #2b8df0;
            margin: 30px 0 10px;
            text-align: justify;
            white-space: pre-wrap;
          }
          .procho {
            .perlist {
              .tc {
                height: 50px;
              }
              .pername {
                height: 50px;
                max-width: 120px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
            .choslist {
              .tc {
                height: 50px;
              }
              .chostitle {
                display: -webkit-box;
                display: -webkit-flex;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                align-items: center;
                justify-content: space-around;
                div {
                  text-align: center;
                  font-size: 20px;
                }
              }
              .choser {
                height: 50px;
                display: -webkit-box;
                display: -webkit-flex;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                align-items: center;
                justify-content: space-around;
                .van-radio {
                  margin: 0;
                  display: -webkit-box;
                  display: -webkit-flex;
                  display: flex;
                  -webkit-box-align: center;
                  -webkit-align-items: center;
                  align-items: center;
                  justify-content: space-around;
                  .checked {
                    width: 1em;
                    height: 1em;
                    background: #ffffff;
                    border: 0.3em solid #2b8df0;
                    border-radius: 50%;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .procho_dafen {
    width: 100%;
    display: flex;
    // align-items: flex-end;
    align-items: center;
    // justify-content: flex-end;
    justify-content: flex-start;
    input {
      // width: calc(100% - 0.7rem);
      width: 1.5rem;
      height: 0.8rem;
      background: #ffffff;
      box-shadow: 0 0 0.08rem rgba(0, 0, 0, 0.18);
      border-radius: 0.13333rem;
      border: 1px solid #ddd;
      margin-bottom: 0.08rem;
      margin-top: 0.16rem;
      text-align: center;
      color: #2b8df0;
      font-size: 26px;
    }
    strong {
      font-size: 32px;
      font-weight: bold;
      margin-left: 16px;
      position: relative;
      bottom: 0.01rem;
      color: #666;
    }
  }
  .procho_dafen_2 {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .procho_dafen_3 {
      width: 1.12rem;
      display: flex;
      align-items: center;
      height: 1.04rem;
      justify-content: center;
    }
    input {
      // width: calc(100% - 0.7rem);
      width: 0.5rem;
      height: 0.5rem;
    }
    strong {
      font-size: 32px;
      font-weight: bold;
      margin-left: 16px;
      position: relative;
      bottom: 0.01rem;
      color: #666;
    }
  }
  .van-dialog {
    .van-dialog__content {
      .person {
        background: #fff;
        padding: 30px;
        margin-bottom: 20px;
        .personlist {
          display: -webkit-box;
          display: -webkit-flex;
          display: flex;
          -webkit-box-align: center;
          -webkit-align-items: center;
          align-items: center;
          flex-direction: column;
          .viewZp {
            width: 1.3rem;
            height: 1.3rem;
            position: relative;
            .re {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              overflow: hidden;
              display: flex;
            }
            img,
            .svg-icon {
              width: 100%;
              height: 100%;
            }
            .del {
              width: 36px;
              height: 36px;
              position: absolute;
              top: 0;
              right: -12px;
            }
          }
          .personname {
            font-size: 0.45rem;
            text-align: center;
          }
        }
      }
    }
  }
  .progress {
    position: fixed;
    height: 200px;
    width: 10px;
    background: #8a8a8a;
    border-radius: 5px;
    bottom: 10px;
    right: 10px;
    z-index: 10;
    .progressSon {
      width: 10px;
      background: #2b8df0;
      border-radius: 5px;
    }
  }
}
.problemTable_Body_Ul {
  width: 100%;
  // padding: 0 32px 0 50px;

  .problemTable_Body_Li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .problemTable_Body_index {
    min-width: 30px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid #ccc;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
  .problemTable_Body_LEFT {
    display: flex;
    // border-bottom: 1px solid #ccc;
    // padding-bottom: 15px;
  }
  .problemTable_Body_BiaoTi {
    font-size: 28px;
    text-align: justify;
    margin-left: 10px;
  }
  .problemTable_Body_RIGHT {
    width: 168px;
    min-width: 168px;
    display: flex;
    align-items: center;
    .problemTable_Body_PD {
      width: 1.24rem;
      text-align: center;
      font-size: 0.42667rem;
      color: #2b8df0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .problemTable_Body_PF {
      width: 1rem;
      min-width: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
}
</style>
